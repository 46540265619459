import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from 'vue-i18n';
import messages from '@intlify/vite-plugin-vue-i18n/messages';
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import apolloProvider from './vue-apollo';
import { Swiper, SwiperSlide } from 'swiper/vue';
import BaseIcon from "./components/Base/BaseIcon.vue";
import BaseImage from "./components/Base/BaseImage.vue";
import BaseSlider from "./components/Base/BaseSlider.vue";
import BasePaginatedList from "./components/Base/BasePaginatedList.vue";
import BaseLimitedList from "./components/Base/BaseLimitedList.vue";
import AtomAccordion from "./components/Atom/AtomAccordion.vue";
import AtomSpeakerTeaser from '@/components/Atom/AtomSpeakerTeaser.vue';
import BlockAccordion from "./components/Block/BlockAccordion.vue";
import BlockSpeakersFilter from "./components/Block/BlockSpeakersFilter.vue";
import BlockEventsFilter from "./components/Block/BlockEventsFilter.vue";
import BlockInpageNavigation from "./components/Block/BlockInpageNavigation.vue";
import SiteHeader from "./components/Site/SiteHeader.vue";
import SiteScrollToTop from "./components/Site/SiteScrollToTop.vue";

import "virtual:svg-icons-register";
import "@css/styles.scss";

const app = createApp({
	components: {
		BaseIcon,
		BaseImage,
		Swiper,
		SwiperSlide,
		BaseSlider,
		BasePaginatedList,
		BaseLimitedList,
		AtomAccordion,
		AtomSpeakerTeaser,
		BlockAccordion,
		BlockSpeakersFilter,
		BlockEventsFilter,
		BlockInpageNavigation,
		SiteHeader,
		SiteScrollToTop,
	},
	delimiters: ["${", "}"],
});

app.use(createPinia());
app.use(apolloProvider);

const i18n = createI18n({
	locale: document.documentElement.lang || 'de',
    fallbackLocale: 'de',
    messages,
	silentFallbackWarn: true,
	missingWarn: false,
    fallbackWarn: false,
})

app.use(i18n);
app.mount("#app");

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept(() => {
	  console.log("HMR")
	});
}
